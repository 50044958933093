const translations = {
    ReportForm: {
        title: "Report absence",
        firstname: "First name",
        surname: "Last name",
        emailaddress: "E-Mail",
        phonenumber: "Phonenumber",
        ahvnumber: "AHV Number",
        VacationRadio: {
            label: "Does the person have holidays before or after the absence?",
            unknown: "Unknown",
            yes: "Yes",
            no: "No",
        },
        ContactCheckbox: {
            label: "Please call back before contacting the absent person.",
            contactphonenumber: "Contactnumber",
        },
        submit: "Submit",
        ThankYou: {
            main: "The absence report was successfully submitted.",
        },
        Errors: {
            uidError: "Your URL is invalid. Please use the QR code.",
            InvalidInput: "Please fill out this field.",
            InvalidEmailInput: "Please enter a valid email address.",
            Error400: "The request is invalid. Please contact Joblife Services.",
            Error404: "The request could not be sent. Please try again later.",
            Error500: "An error occurred while processing your data. Please try again later.",
            ErrorUnknown: "An unknown error has occurred. Please try again later."
        }
    },
    QrGeneratorForm: {
        title: "QR-Code Generator",
        companyUid: "Company-UID",
        generateButton: "Generate",
        linkToWebapp: "App link",
        copyLinkTitle: "Copy Link",
        qrCode: "QR-Code",
        downloadQrCode: "Download QR-Code",
        snackbarText: "Copied to clipboard!"
    }
}

export default translations;