import React, { useState } from "react";

import { TextField, Button, Snackbar, IconButton } from "@mui/material";
import { ThemeProvider, styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";

import theme from "../themes/JlsTheme";
import { useLocalization } from "../hooks/useLocalization";
import logo from '../logo.png';
import './QrGeneratorForm.css';

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.color.primary,
    color: theme.color.font,
    borderColor: theme.color.primaryDark,
    marginTop: "32px",
    "&:hover": {
        backgroundColor: theme.color.primaryDark,
    }
}))

function QrGeneratorForm() {
    const localizedStrings = useLocalization();

    const [webappLink, setWebappLink] = useState("");
    const [isShown, setShown] = useState(false);
    const [isClicked, setClicked] = useState(false);
    const [companyUid, setCompanyUid] = useState("");

    const handleSubmit = (event: any) => {
        setShown(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        const companyUid = data.companyUid.toString();
        setCompanyUid(companyUid);
        const encodedCompanyUid = window.btoa(companyUid);
        // Url of the Current Website
        // if Created in DEV its the DEV Url, if Created in Demo its Demo, etc.
        const reportAppUrlBase = window.location.host;
        const reportAppUrl = "https://" + reportAppUrlBase + "/u/" + encodedCompanyUid;
        setWebappLink(reportAppUrl);
    }

    const handleClick = (event: any) => {
        if (navigator.clipboard) {     
            setClicked(true);
            navigator.clipboard.writeText(webappLink);
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setClicked(false);
    };

    const handleDownload = (event: any) => {
        const qrCode = document.getElementById("qrCode") as HTMLCanvasElement;
        if (qrCode !== null) {
            const anchor = document.createElement("a");
            anchor.href = qrCode.toDataURL("image/png");
            const code = window.btoa(Date.now().toString());
            anchor.download = "JLS__App_" + companyUid + ".png";
            anchor.click();
        }
    }

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className="QrGeneratorForm">
            <h1>{localizedStrings.QrGeneratorForm.title}</h1>
            <ThemeProvider theme={theme}>
                <form method="POST" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        required
                        id="outlined-basic"
                        label={localizedStrings.QrGeneratorForm.companyUid}
                        variant="outlined"
                        margin="normal"
                        name="companyUid"
                        type="text"
                    />
                    <CustomButton
                        variant="contained"
                        size="large"
                        type="submit">
                        {localizedStrings.QrGeneratorForm.generateButton}
                    </CustomButton>
                </form>
                {isShown && (
                    <div>
                        <br />
                        <hr />
                        <h2>{localizedStrings.QrGeneratorForm.linkToWebapp}</h2>
                        <span className="WebappUrl" onClick={handleClick} title="Kopieren">{webappLink} <ContentCopyIcon fontSize="small" /></span>
                        <h2>{localizedStrings.QrGeneratorForm.qrCode}</h2>
                        <div>
                            <QRCodeCanvas
                                id="qrCode"
                                value={webappLink}
                                size={256}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"M"}
                                includeMargin={false}
                                imageSettings={{
                                    src: logo,
                                    x: undefined,
                                    y: undefined,
                                    height: 32,
                                    width: 107,
                                    excavate: true,
                                }}
                            />
                        </div>

                        <CustomButton
                            variant="contained"
                            size="large"
                            onClick={handleDownload}
                        >
                            {localizedStrings.QrGeneratorForm.downloadQrCode}
                        </CustomButton>
                        <Snackbar
                            open={isClicked}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            message={localizedStrings.QrGeneratorForm.snackbarText}
                            action={snackbarAction}
                        />
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
}

export default QrGeneratorForm;