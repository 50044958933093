import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { getEnvironmentApiUri } from '../hooks/getEnvironmentApiUri';

const restApiBaseUrl = getEnvironmentApiUri();

export default {
    createReport(body: object) {

        const headers: AxiosRequestHeaders = getBaseHttpRequestHeaders();

        return axios.post<any, AxiosResponse<void>>(
            `${restApiBaseUrl}`,
            body,
            {
                headers,
            },
        ).then(() => {
            return;
        }).catch(error => {
            console.error(error.message);
            throw error;
        })
    },
}

function getBaseHttpRequestHeaders() {
    const headers: AxiosRequestHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    return headers;
}