import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import LocalizedStrings from 'react-localization';
import de from '../services/translations/de';
import en from '../services/translations/en';
import fr from '../services/translations/fr';

const init = new LocalizedStrings({
    de,
    en,
    fr
});

let globalSetLanguage: any = () => {
    throw new Error("you must useLocalization before setting its language");
};

init.setLanguage('de');

export const useLocalization = singletonHook(init, () => {
    const [localizedStrings, setLocalizedStrings] = useState(init);
    globalSetLanguage = (language: string) => {
        if (localizedStrings.getAvailableLanguages().indexOf(language) !== -1) {
            localizedStrings.setLanguage(language);
        }
    };
    return localizedStrings;
})

export const setLanguage = (alert: any) => globalSetLanguage(alert);