const translations = {
    ReportForm: {
        title: "Rapport d'absence",
        firstname: "Prénom",
        surname: "Nom",
        emailaddress: "E-Mail",
        phonenumber: "N° de téléphone",
        ahvnumber: "N° AVS",
        VacationRadio: {
            label: "La personne est-elle en vacances avant ou après son absence ?",
            unknown: "Inconnu",
            yes: "Oui",
            no: "Non",
        },
        ContactCheckbox: {
            label: "Merci de rappeler avant de contacter la personne absente.",
            contactphonenumber: "N° de contact",
        },
        submit: "Soumettre",
        ThankYou: {
            main: "L'avis d'absence a été soumis avec succès.",
        },
        Errors: {
            uidError: "Votre URL n'est pas valide. Veuillez utiliser le code QR.",
            InvalidInput: "Veuillez remplir ce champ.",
            InvalidEmailInput: "Mettez une adresse email valide, s'il vous plait.",
            Error400: "La demande n'est pas valide. Veuillez contacter Joblife Services.",
            Error404: "La demande n'a pas pu être envoyée. Veuillez réessayer plus tard.",
            Error500: "Une erreur s'est produite lors du traitement de vos données. Veuillez réessayer plus tard.",
            ErrorUnknown: "Une erreur inconnue s'est produite. Veuillez réessayer plus tard."
        }
    },
    QrGeneratorForm: {
        title: "Générateur de codes QR",
        companyUid: "UID de l'entreprise",
        generateButton: "Générer",
        linkToWebapp: "lien d'application",
        copyLinkTitle: "Copie",
        qrCode: "QR-Code:",
        downloadQrCode: "Télécharger le code QR",
        snackbarText: "Copié dans le presse-papier!"
    }
}

export default translations;