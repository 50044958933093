import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {
    Grid,
    TextField,
    FormControlLabel,
    Button,
    FormGroup,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Checkbox,
    CircularProgress,
} from "@mui/material";
import { ThemeProvider, styled } from "@mui/material/styles";

import theme from "../themes/JlsTheme";
import { useLocalization } from "../hooks/useLocalization";
import RestApiClient from "../services/RestApiClient";
import './ReportForm.css';

const RequiredTextField = styled(TextField)({
    "& label": {
        color: "black",
        fontWeight: "bold",
    },
    "& .MuiFormLabel-asterisk": {
        color: "red",
        fontWeight: "bolder",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "black",
            borderWidth: "2px",
        },
    },
});

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.color.primary,
    color: theme.color.font,
    borderColor: theme.color.primaryDark,
    marginTop: "32px",
    "&:hover": {
        backgroundColor: theme.color.primaryDark,
    },
}));

function ReportForm() {
    const localizedStrings = useLocalization();

    const [isShown, setIsShown] = useState(false);

    const [isSubmitted, setSubmitted] = useState(false);

    const [hasFailed, setFailed] = useState(false);

    const [isDisabled, setDisabled] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const { companyUid } = useParams();

    let decodedCompanyUid: string | undefined;

    if (companyUid !== undefined) {
        try {
            decodedCompanyUid = window.atob(companyUid);
        } catch (error) {
            //ignore - Error will be displayed when decodedCompanyUid is empty
        }
    }

    const handleCheckboxOnChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsShown((current) => !current);
    };

    const handleInputOnInvalid = (
        event: React.InvalidEvent<HTMLInputElement>
    ) => {
        if (event.target.type === "email") {
            event.target.setCustomValidity(
                localizedStrings.ReportForm.Errors.InvalidEmailInput
            );
        } else {
            event.target.setCustomValidity(
                localizedStrings.ReportForm.Errors.InvalidInput
            );
        }
    };

    // Event: Any, because React thinks that OnInput is to new
    const handleInputOnInput = (event: any) => {
        event.target.setCustomValidity("");
    };

    const handleSubmit = (event: any) => {
        setDisabled(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        if (decodedCompanyUid !== undefined) {

            data.companyUid = decodedCompanyUid;

            RestApiClient.createReport(data)
                .then(() => {
                    setSubmitted(true);
                })
                .catch((error) => {
                    setFailed(true);
                    var errorMessage = "";

                    if (error.response.status === 404) {
                        // 404 Not Found
                        errorMessage = localizedStrings.ReportForm.Errors.Error404;
                    } else if (error.response.status === 400) {
                        // 400 Bad Request
                        errorMessage = localizedStrings.ReportForm.Errors.Error400;
                    } else if (error.response.status === 500) {
                        // 500 Internal Server Error
                        errorMessage = localizedStrings.ReportForm.Errors.Error500;
                    } else {
                        // Unknown Error
                        errorMessage = localizedStrings.ReportForm.Errors.ErrorUnknown;
                    }

                    setErrorMessage(errorMessage);
                    setDisabled(false);
                });
        } else {
            setFailed(true);
            setDisabled(false);
        }
    };

    return (
        <div>
            {isSubmitted ? (
                <div>
                    <h1>{localizedStrings.ReportForm.title}</h1>
                    <p>{localizedStrings.ReportForm.ThankYou.main}</p>
                </div>
            ) : decodedCompanyUid ? (
                <div className="ReportForm">
                    <h1>{localizedStrings.ReportForm.title}</h1>
                    <ThemeProvider theme={theme}>
                        {hasFailed && (
                            <span className="errorMessage">{errorMessage}
                            </span>
                        )}
                        <form method="POST" onSubmit={handleSubmit}>
                            <Grid container spacing={2} columnSpacing={2}>
                                <Grid item xs={12} md={6}>
                                    <RequiredTextField
                                        fullWidth
                                        id="outlined-basic"
                                        title={localizedStrings.ReportForm.Errors.InvalidInput}
                                        label={localizedStrings.ReportForm.firstname}
                                        variant="outlined"
                                        required
                                        margin="normal"
                                        name="firstName"
                                        onInvalid={handleInputOnInvalid}
                                        onInput={handleInputOnInput}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RequiredTextField
                                        fullWidth
                                        id="outlined-basic"
                                        title={localizedStrings.ReportForm.Errors.InvalidInput}
                                        label={localizedStrings.ReportForm.surname}
                                        variant="outlined"
                                        required
                                        margin="normal"
                                        name="name"
                                        onInvalid={handleInputOnInvalid}
                                        onInput={handleInputOnInput}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RequiredTextField
                                        fullWidth
                                        id="outlined-basic"
                                        title={localizedStrings.ReportForm.Errors.InvalidEmailInput}
                                        label={localizedStrings.ReportForm.emailaddress}
                                        type="email"
                                        variant="outlined"
                                        required
                                        margin="normal"
                                        name="email"
                                        onInvalid={handleInputOnInvalid}
                                        onInput={handleInputOnInput}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RequiredTextField
                                        fullWidth
                                        id="outlined-basic"
                                        title={localizedStrings.ReportForm.Errors.InvalidInput}
                                        label={localizedStrings.ReportForm.phonenumber}
                                        type="tel"
                                        variant="outlined"
                                        required
                                        margin="normal"
                                        name="phoneNumber"
                                        onInvalid={handleInputOnInvalid}
                                        onInput={handleInputOnInput}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label={localizedStrings.ReportForm.ahvnumber}
                                        variant="outlined"
                                        margin="normal"
                                        name="ahvNumber"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="dense">
                                        <FormLabel>
                                            {localizedStrings.ReportForm.VacationRadio.label}
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="vacation-radio-group-label"
                                            defaultValue="unknown"
                                            name="vacation"
                                            row
                                        >
                                            <FormControlLabel
                                                value="unknown"
                                                control={<Radio />}
                                                label={
                                                    localizedStrings.ReportForm.VacationRadio.unknown
                                                }
                                            />
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label={localizedStrings.ReportForm.VacationRadio.yes}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio />}
                                                label={localizedStrings.ReportForm.VacationRadio.no}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="reply"
                                                    onChange={handleCheckboxOnChange}
                                                    value={true}
                                                />
                                            }
                                            label={localizedStrings.ReportForm.ContactCheckbox.label}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {isShown && (
                                <Grid item xs={12}>
                                    <RequiredTextField
                                        title={localizedStrings.ReportForm.Errors.InvalidInput}
                                        fullWidth
                                        id="outlined-basic"
                                        label={
                                            localizedStrings.ReportForm.ContactCheckbox
                                                .contactphonenumber
                                        }
                                        type="tel"
                                        variant="outlined"
                                        required
                                        margin="normal"
                                        name="contactPhoneNumber"
                                        onInvalid={handleInputOnInvalid}
                                        onInput={handleInputOnInput}
                                    />
                                </Grid>
                            )}
                            <CustomButton
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isDisabled}
                                startIcon={isDisabled ? <CircularProgress size={20} /> : ""}>
                                {localizedStrings.ReportForm.submit}
                            </CustomButton>
                        </form>
                    </ThemeProvider>
                </div>
            ) : ( // decodedCompanyUid is missing -> error
                <div>
                    <h1 className="errorMessage errorLargeSpacing">{localizedStrings.ReportForm.Errors.uidError}</h1>
                </div>
            )}
        </div>
    );
}

export default ReportForm;
