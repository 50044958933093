export const getEnvironmentApiUri = () => {
    const appUrl = window.location.href;

    if (appUrl.includes("https://jls-report-webapp.azurewebsites.net") || appUrl.includes("https://app.joblife-services.ch/")) {
        // Prod
        return process.env.REACT_APP_REST_API_PROD_URI;
    } else if (appUrl.includes("demo")) {
        // Demo
        return process.env.REACT_APP_REST_API_DEMO_URI;
    } else {
        // Dev
        return process.env.REACT_APP_REST_API_DEV_URI;
    }
}